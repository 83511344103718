import React from 'react';
import { experience } from './data/experience';
import Particle from './Particle'

const Experience = () => {
    return (
        <section id="experience" className="experience">
            <div className="d-flex justify-content-center my-5">
                <h1>Experience</h1>
            </div>
            <div className="container experience-wrapper">
                {experience.map((item, key) => {
                    if (item.id % 2 !== 1) {
                        return (
                            <div className='timeline-block timeline-block-right' key={key}>
                                <img
                                    src={item.logo}
                                    alt={item.company}
                                    className="experience-logo"
                                />
                                <div className='marker'></div>

                                <div className='timeline-content'>
                                    <h3>{item.title}</h3>
                                    <h4>{item.company}</h4>
                                    <h5>{item.location}</h5>
                                    <h5 className='role'>{item.role}</h5>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div className='timeline-block timeline-block-left' key={key}>
                                <img
                                    src={item.logo}
                                    alt={`${item.company} Logo`}
                                    className="experience-logo"
                                />
                                <div className='marker'></div>
                                <div className='timeline-content'>
                                    <h3>{item.title}</h3>
                                    <h4>{item.company}</h4>
                                    <h5>{item.location}</h5>
                                    <h5 className='role'>{item.role}</h5>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
        </section>
    )
}

export default Experience;