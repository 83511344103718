import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { getPortfolioDataById } from './data/portfolio'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faRectangleXmark } from '@fortawesome/free-solid-svg-icons'
// import { faBuromobelexperte } from '@fortawesome/free-brands-svg-icons'
const PortfolioInfo = () => {
  let params = useParams()
  let portfolioItemId = getPortfolioDataById(parseInt(params.portfolioId))
  return (
    <>
      <div className='portfolio-info'>
        <Link to='/'>
          <FontAwesomeIcon className='cancelIcon' icon={faRectangleXmark} />
        </Link>
        <h2 className='portfolio-title'>{portfolioItemId.title}</h2>
        <img src={portfolioItemId.img} alt={portfolioItemId.title} />
        <p style={{ color: 'white' }}>{portfolioItemId.description}</p>
        <a className='portfolio-link' href={portfolioItemId.github} target='_blank'>
          GITHUB: {portfolioItemId.title}
        </a>
        {
          () => {
            if (portfolioItemId.demo != null) {
              return (
                <a className='portfolio-link' href={portfolioItemId.demo} target='_blank'>
                  DEMO: {portfolioItemId.title}
                </a>)
            }
          }
        }

      </div>
      <div className='portfolio-overlay'></div>
    </>
  )
}

export default PortfolioInfo
