import React from "react";
import GKhublogo from "../GKhub-logo.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-scroll'

const Navbar = () => {
        const navLinks = [
            'home',
            'about',
            'summary',
            'experience',
            // 'portfolio',
            // 'testimonials',
            // 'contacts',
        ]
        return (
            <nav className="navbar navbar-expand-lg sticky-top">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/"><img className="logo" src={GKhublogo} alt="GKhub" /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <FontAwesomeIcon icon={faBars} style={{ color: "#137dc5" }} />
                    </button>
                    <div className='collapse navbar-collapse' id='navbarNav'>
                        <ul className='navbar-nav mx-auto'>
                            {navLinks.map((link) => (
                                <li className='nav-item' key={link}>
                                    <Link
                                        to={link}
                                        smooth={true}
                                        offset={-70}
                                        duration={150}
                                        spy={true}
                                        activeClass="active"
                                    >
                                        {link}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                </div>
                </div>
            </nav>
        )
    }

    export default Navbar