import React, { useState, useEffect } from "react";
import { ReactTyped } from "react-typed";
import Particle from './Particle'

const Header = () => {
  
  const [isLoading, setIsLoading] = useState(true);

  const handleParticlesLoaded = () => {
    setIsLoading(false);
  };
 
  return (
    <>
      {isLoading && (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      )}
      <div className={`content ${isLoading ? 'loading' : ''}`}>
        <div className="particle-container">
          <Particle particlesArea='tsparticlesHeader' onLoad={handleParticlesLoaded} />
        </div>
        <div className="header-wraper" id="home">
          <div className="main-info">
            <h1>Welcome to the GKhub</h1>
            <h2>What I am currently working on?</h2>
            <ReactTyped
              className="typed-text"
              strings={["React JS", ".NET Core", "DevOps on Azure/AWS"]}
              typeSpeed={40}
              backSpeed={60}
              loop
            />
            <a href="#contact" className="btn-main-offer">Contact Me</a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header;

