import React from 'react'
import { aboutme } from './data/aboutme.js';
import { Carousel } from 'react-responsive-carousel'

const AboutMe = () => {
    return (
        <section id='about' className='container py-5 about-wrapper'>
            <div className='row'>
                <div className='col-lg-6 col-xm-12'>
                    <h2 className='text-uppercase about-heading'>{aboutme.Title}</h2>
                    <p>
                        {aboutme.Main}
                    </p>
                </div>
                <div className='col-lg-6 col-xm-12'>
                    <div className='photo-wrap'>
                        <Carousel
                            infiniteLoop={true}
                            interval={2500}
                            autoPlay={true}
                            showThumbs={false}
                            showStatus={false}
                        >
                            {aboutme.Images.map((item, index) => (
                                <div>
                                    <img className='profile-img' src={item} alt={`profileImage-${index}`} />
                                </div>
                            ))}
                        </Carousel>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default AboutMe;