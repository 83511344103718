import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { summary } from './data/summary'

const Summary = () => {
  return (
    <section id='summary' className='summary'>
        <h1 className='py-5'>Summary</h1>
        <div className='container'>
            <div className='row'>
                {summary.map((item, key) => (
                    <div className='col-md-3 col-sm-6' key={key}>
                    <div className='box'>
                        <div className='circle'>
                            <FontAwesomeIcon className='icons' icon={item.icon} />
                        </div>
                        <h3>{item.title}</h3>
                        <ul>{item.items.map((item, key) => (<li key={key}>{item}</li>))}</ul>
                    </div>
                </div>
                ))}
            </div>
        </div>
    </section>
  )
}

export default Summary;